
<template>
	<div :class="{
'searchbox':true,
'bingosearchbox':bingosearchbg
	}">
		<div class="searchcon">
			<div class="searchtitle">
				搜索最新资讯
			</div>
			<div class="searchwarper">
				<div class="search">
					<input style="padding-left:5px; font-size:14px" v-model="search" type="text" placeholder="请输入关键字">
				</div>
				<div class="searchbtn" @click="toSearch">
					<img src="@/assets/images/sousuo.png" alt="">
				</div>
			</div>


		</div>
	</div>
</template>

<script>


export default {

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	data() {
		return {
			search: '',
			bingosearchbg:false
		}



	},
	watch: {
		site: {
			handler(ov, nv) {

			},
			deep: true
		}
	},

	mounted() {
	
		const str = this.$route.query.c

		if(this.$route.name === 'detailjujian' ||this.$route.name === 'listjujian' ){
			this.bingosearchbg = true
		}else{
			this.bingosearchbg = false
		}
		this.search = str
	},

	
	methods: {
		toSearch() {
			if (this.search !== '') {
				const url = '/' + this.site.path + '/list/search?c=' + this.search
				this.$router.push(url)
			} else {
				this.$message.info('请输入要搜索的内容')
			}
		}
	}

}
</script>

<style lang="scss" scoped>
.searchbox {
	height: 186px;
	width: 100%;
	background: url('../../assets/images/headersearchbg.jpg') no-repeat center;
	background-size: cover;
	min-width: 1200px;
	.searchcon {
		width: 1200px;
		height: 186px;
		margin: 0 auto;
		// background: red;
		padding-top: 71px;


		.searchtitle {
			width: 108px;
			height: 25px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 25px;

		}

		.searchwarper {
			display: flex;
			margin-top: 10px;

			.search {
				width: 462px;
				height: 40px;
				background: #FFFFFF;

				input {
					width: 458px;
					height: 38px;
					border: none;
					outline: none;
				}
			}

			.searchbtn {
				width: 80px;
				height: 40px;
				background: #00A3E4;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				img {
					width: 18px;
					height: 18px;
				}
			}

		}






	}
}

.bingosearchbox {
	height: 186px;
	width: 100%;
	background: url('../../assets/images/bingosearchbg.jpeg') no-repeat center;
	background-size: cover;
	min-width: 1200px;
	.searchcon {
		width: 1200px;
		height: 186px;
		margin: 0 auto;
		// background: red;
		padding-top: 71px;


		.searchtitle {
			width: 108px;
			height: 25px;
			font-size: 18px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #FFFFFF;
			line-height: 25px;

		}

		.searchwarper {
			display: flex;
			margin-top: 10px;

			.search {
				width: 462px;
				height: 40px;
				background: #FFFFFF;

				input {
					width: 458px;
					height: 38px;
					border: none;
					outline: none;
				}
			}

			.searchbtn {
				width: 80px;
				height: 40px;
				background: #00A3E4;
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;

				img {
					width: 18px;
					height: 18px;
				}
			}

		}






	}
}






.lunbox {
	position: relative;
	width: 100%;
	height: 360px;
	overflow: hidden;

	.lunboimg {
		// width: 2680px;
		min-width: 100%;
		height: 360px;

		display: inline-block;
		/* 以下为图片能居中的主要代码，需父级user-img相对定位 position:relative; */
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
</style>