<template>


	<div class="breamline">
		<div class="indexmenu" @click="toindex">首页</div>
		<div class="itemsingle" v-for="item in list" :key="item.title">
			<img class="righticon" src="@/assets/images/right.png">
			<div class="title" @click="tourl(item)">{{ item.title }}</div>
		</div>


	</div>
</template>

<script>
export default {
	name: 'PcWebsiteBreadLine',
	props: {
		showLevel: {
			type: Number,
			default: 1   //0 最后一级   1 第一级  2 两级
		}, //显示级别
	},

	data() {
		return {
			list: []
		};
	},
	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},


	watch:{
		$route:{
			handler(nv){
				this.init()
			},
			deep:true,
			immediate:true
		}
	},


	mounted() {
		this.init()
	},

	methods: {
		init() {
			const route = this.$route
			console.log('route', route)
			let list = []

			if (this.showLevel === 0) {
				route.meta.url = route.fullPath
				list.push(route.meta)
			}

			if (this.showLevel === 1) {
				if (this.$route.matched[0]) {
					route.matched[0].meta.url = route.matched[0].path
					list.push(route.matched[0].meta)
				}
			}

			if (this.showLevel === 2) {
				if (this.$route.matched[0]) {
					route.matched[0].meta.url = route.matched[0].path
					list.push(route.matched[0].meta)
				}
				if (this.$route.matched[1]) {
					route.matched[1].meta.url = route.matched[1].path
					list.push(route.matched[1].meta)
				}
			}

			this.list = list

		},

		toindex() {
			const tourl = '/' + this.site.path + '/index'
			this.$router.push(tourl)
		},
		// tourl(tourl) {
		// 	alert(this.site.path)
		// 	if (tourl.url) {
		// 		tourl.url = tourl.url.replace(':city', this.site.path)
		// 	}
		// 	this.$router.push(tourl.url)
		// }
	},
};
</script>

<style lang="scss" scoped>
.indexmenu {
	width: 28px;
	height: 20px;
	font-size: 14px;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #848484;
	line-height: 20px;
	cursor: pointer;
}

.breamline {
	width: 1200px;
	height: 60px;
	margin: 0 auto;
	background: '#F0F3F8';

	display: flex;
	justify-content: flex-start;
	align-items: center;

	.itemsingle {
		display: flex;
		justify-content: center;
		align-items: center;

		.righticon {
			width: 12px;
			height: 12px;
			margin-left: 5px;
			margin-right: 5px;
		}

		.title {
			height: 20px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #000000;
			line-height: 20px;
		}

	}
}
</style>